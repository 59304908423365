import { NodeType } from "../models/nodeType";

const useInitialNodeTypes = (): NodeType[] => {
  return [
    {
      label: "Start",
      color: "red.800",
      nodeName: "start",
      nodeCategory: "Start/End",
      nodeClass: "start",
      nodeComponentName: "StartNode",
      nodeData: {},
      sourceHandles: [
        {
          label: "Start",
          handleName: "start",
          handleType: "source",
          handleCategory: "start",
        },
      ],
      isReady: true,
      isEditable: true,
      isSourceHandlesEditable: true,
      ignoreSourceHandlesDiff: true,
    },
    {
      label: "Long-term Memory",
      color: "purple.600",
      nodeName: "long_term_memory",
      nodeComponentName: "LongTermMemoryNode",
      nodeCategory: "Flow",
      nodeClass: "flow",
      nodeData: {
        flowTargetCount: 1,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      isTargetHandlesEditable: true,
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      isSourceHandlesEditable: true,
      ignoreSourceHandlesDiff: true,
      isEditable: true,
      isReady: true,
      nodeDescription: "Store long-term memory",
    },
    {
      label: "Fetch Data",
      color: "blue.600",
      nodeName: "fetch_data",
      nodeComponentName: "FetchDataNode",
      nodeCategory: "Flow",
      nodeClass: "flow",
      nodeData: {
        flowTargetCount: 1,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      isTargetHandlesEditable: true,
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "DATA",
          handleName: "data",
          handleType: "source",
          handleCategory: "data",
        },
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      isSourceHandlesEditable: true,
      ignoreSourceHandlesDiff: true,
      isEditable: true,
      isReady: true,
      nodeDescription: "Fetch data from context as a JSON",
    },
    {
      label: "Create-Fetch Session",
      color: "blue.600",
      nodeName: "create_fetch_session",
      nodeComponentName: "CreateFetchSessionNode",
      nodeCategory: "Data",
      nodeClass: "data",
      nodeData: {
        npcId: "{{npcId}}",
      },
      targetHandles: [],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "Session ID",
          handleName: "sessionId",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Create or fetch session",
    },
    {
      label: "Custom Condition",
      color: "purple.600",
      nodeName: "custom_condition",
      nodeComponentName: "CustomConditionNode",
      nodeCategory: "Logic",
      nodeClass: "flow",
      nodeData: {
        flowTargetCount: 1,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      isTargetHandlesEditable: true,
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "0",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
        {
          label: "1",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      isSourceHandlesEditable: true,
      ignoreSourceHandlesDiff: true,
      isEditable: true,
      isReady: true,
      nodeDescription: "Define custom conditions",
    },
    {
      label: "Cappy Evaluation",
      color: "cyan.600",
      nodeName: "cappy_evaluation",
      nodeComponentName: "CappyEvaluationNode",
      nodeCategory: "Flow",
      nodeClass: "flow",
      nodeData: {
        flowTargetCount: 1,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      isTargetHandlesEditable: true,
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      isSourceHandlesEditable: true,
      ignoreSourceHandlesDiff: true,
      isEditable: true,
      isReady: true,
      nodeDescription: "Evaluate Question-Answer pairs",
    },
    {
      label: "Set Session Status",
      color: "purple.600",
      nodeName: "set_session_status",
      nodeComponentName: "SetSessionStatusNode",
      nodeCategory: "Flow",
      nodeClass: "flow",
      nodeData: {
        flowTargetCount: 1,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Session ID",
          handleName: "sessionId",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Set session status",
    },
    {
      label: "Transform Data",
      color: "purple.600",
      nodeName: "transform_data",
      nodeComponentName: "TransformDataNode",
      nodeCategory: "Flow",
      nodeClass: "flow",
      nodeData: {
        flowTargetCount: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Transform data",
    },
    {
      label: "End",
      color: "red.800",
      nodeName: "end",
      nodeComponentName: "EndNode",
      nodeCategory: "Start/End",
      nodeClass: "end",
      nodeData: {
        flowTargetCount: 1,
      },
      targetHandles: [
        {
          label: "End",
          handleName: "end",
          handleType: "target",
          handleCategory: "end",
        },
      ],
      ignoreTargetHandlesDiff: true,
      isReady: true,
    },
    {
      label: "AI Completion",
      color: "cyan.600",
      nodeName: "ai_completion",
      nodeComponentName: "CreateAiCompletionNode",
      nodeCategory: "Flow",
      nodeClass: "flow",
      nodeData: {
        isDataPortEnabled: false,
        flowTargetCount: 1,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      isTargetHandlesEditable: true,
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "DATA",
          handleName: "data",
          handleType: "source",
          handleCategory: "data",
        },
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      isSourceHandlesEditable: true,
      ignoreSourceHandlesDiff: true,
      isEditable: true,
      isReady: true,
    },
    {
      label: "Semantic Search",
      color: "purple.600",
      nodeName: "search_semantic_data",
      nodeComponentName: "SemanticSearchNode",
      nodeCategory: "Flow",
      nodeClass: "flow",
      nodeData: {
        isDataPortEnabled: false,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      isTargetHandlesEditable: true,
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "DATA",
          handleName: "data",
          handleType: "source",
          handleCategory: "data",
        },
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      isSourceHandlesEditable: true,
      ignoreSourceHandlesDiff: true,
      isEditable: true,
      isReady: true,
    },
    {
      label: "NPC Key-Value Update",
      color: "blue.600",
      nodeName: "key_value_update",
      nodeComponentName: "NpcKeyValueUpdateNode",
      nodeCategory: "Flow",
      nodeClass: "flow",
      nodeData: {
        isDataPortEnabled: false,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      isTargetHandlesEditable: true,
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      isSourceHandlesEditable: true,
      ignoreSourceHandlesDiff: true,
      isEditable: true,
      isReady: true,
    },
    {
      label: "NPC Key-Value Find",
      color: "blue.600",
      nodeName: "key_value_find",
      nodeComponentName: "NpcKeyValueFindNode",
      nodeCategory: "Flow",
      nodeClass: "flow",
      nodeData: {
        isDataPortEnabled: false,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      isTargetHandlesEditable: true,
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      isSourceHandlesEditable: true,
      ignoreSourceHandlesDiff: true,
      isEditable: true,
      isReady: true,
    },
    {
      label: "Player Key-Value Update",
      color: "blue.600",
      nodeName: "user_key_value_update",
      nodeComponentName: "PlayerKeyValueUpdateNode",
      nodeCategory: "Flow",
      nodeClass: "flow",
      nodeData: {
        isDataPortEnabled: false,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      isTargetHandlesEditable: true,
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      isSourceHandlesEditable: true,
      ignoreSourceHandlesDiff: true,
      isEditable: true,
      isReady: true,
    },
    {
      label: "Player Key-Value Find",
      color: "blue.600",
      nodeName: "user_key_value_find",
      nodeComponentName: "PlayerKeyValueFindNode",
      nodeCategory: "Flow",
      nodeClass: "flow",
      nodeData: {
        isDataPortEnabled: false,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      isTargetHandlesEditable: true,
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      isSourceHandlesEditable: true,
      ignoreSourceHandlesDiff: true,
      isEditable: true,
      isReady: true,
    },
    {
      label: "Extract Boolean",
      color: "blue.600",
      nodeName: "extract_value",
      nodeComponentName: "CreateExtractValueNode",
      nodeCategory: "Data",
      nodeClass: "data",
      nodeData: {},
      targetHandles: [
        {
          label: "Data",
          handleName: "data",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      isTargetHandlesEditable: true,
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "Boolean",
          handleName: "boolean",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isSourceHandlesEditable: true,
      ignoreSourceHandlesDiff: true,
      isEditable: true,
      isReady: true,
    },
    {
      label: "Boolean Operator",
      color: "orange.600",
      nodeName: "boolean_operator",
      nodeCategory: "Logic",
      nodeClass: "logic",
      nodeComponentName: "BooleanOperatorNode",
      nodeData: {
        operator: "not",
        inputCount: 1,
      },
      targetHandles: [
        {
          label: "Boolean",
          handleName: "boolean",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      isTargetHandlesEditable: true,
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "Boolean",
          handleName: "boolean",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isSourceHandlesEditable: true,
      ignoreSourceHandlesDiff: true,
      isEditable: true,
      isReady: true,
      nodeDescription: "Apply boolean operations on inputs",
    },
    {
      label: "Condition",
      color: "purple.600",
      nodeName: "condition",
      nodeCategory: "Logic",
      nodeClass: "flow",
      nodeComponentName: "FlowNode",
      nodeData: {
        flowTargetCount: 1,
      },
      targetHandles: [
        {
          label: "Boolean",
          handleName: "boolean",
          handleType: "target",
          handleCategory: "data",
        },
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "True",
          handleName: "true",
          handleType: "source",
          handleCategory: "flow",
        },
        {
          label: "False",
          handleName: "false",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Use a boolean to control the flow of the AI workflow",
    },
    {
      label: "Execute Workflow",
      color: "green.600",
      nodeName: "execute_workflow",
      nodeCategory: "Flow",
      nodeClass: "flow",
      nodeComponentName: "ExecuteWorkflowNode",
      nodeData: {
        flowTargetCount: 1,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Execute AI workflow",
    },
    {
      label: "Execute Prompt Actions",
      color: "green.600",
      nodeName: "execute_prompt_actions",
      nodeCategory: "Flow",
      nodeClass: "flow",
      nodeComponentName: "FlowNode",
      nodeData: {
        flowTargetCount: 1,
      },
      targetHandles: [
        {
          label: "Session ID",
          handleName: "sessionId",
          handleType: "target",
          handleCategory: "data",
        },
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Execute the actions AI has decided to take",
    },
    {
      label: "Boolean",
      color: "blue.400",
      nodeName: "boolean",
      nodeCategory: "Logic",
      nodeClass: "data",
      nodeComponentName: "BooleanNode",
      nodeData: {
        value: false,
      },
      sourceHandles: [
        {
          label: "Boolean",
          handleName: "boolean",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      nodeDescription: "A boolean value",
    },
    {
      label: "NPC Action",
      color: "green.600",
      nodeName: "action",
      nodeComponentName: "NPCActionNode",
      nodeCategory: "Flow",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        isDataPortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Session ID",
          handleName: "sessionId",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      isTargetHandlesEditable: true,
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
        {
          label: "Has Errored",
          handleName: "boolean",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isSourceHandlesEditable: true,
      ignoreSourceHandlesDiff: true,
      isEditable: true,
      isReady: true,
    },
    {
      label: "Join (Intersection)",
      color: "pink.600",
      nodeName: "intersection_join",
      nodeComponentName: "IntersectionJoinNode",
      nodeCategory: "Flow",
      nodeClass: "flow",
      nodeData: {},
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      isTargetHandlesEditable: true,
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      isSourceHandlesEditable: true,
      ignoreSourceHandlesDiff: true,
      isEditable: true,
      isReady: true,
    },
    {
      label: "Array Loop",
      color: "yellow.600",
      nodeName: "array_loop",
      nodeComponentName: "ArrayLoopNode",
      nodeCategory: "Flow",
      nodeClass: "flow",
      nodeData: {},
      targetHandles: [
        {
          label: "ELEMENT",
          handleName: "element",
          handleType: "target",
          handleCategory: "element",
        },
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      isTargetHandlesEditable: true,
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "ELEMENT",
          handleName: "element",
          handleType: "source",
          handleCategory: "element",
        },
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      isSourceHandlesEditable: true,
      ignoreSourceHandlesDiff: true,
      isEditable: true,
      isReady: true,
    },
    {
      label: "Iteration Start",
      color: "yellow.600",
      nodeName: "iteration_start",
      nodeComponentName: "IterationStartNode",
      nodeCategory: "Element",
      nodeClass: "element",
      nodeData: {},
      targetHandles: [
        {
          label: "ELEMENT",
          handleName: "element",
          handleType: "target",
          handleCategory: "element",
        },
      ],
      isTargetHandlesEditable: true,
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      isSourceHandlesEditable: true,
      ignoreSourceHandlesDiff: true,
      isEditable: true,
      isReady: true,
    },
    {
      label: "Iteration End",
      color: "yellow.600",
      nodeName: "iteration_end",
      nodeComponentName: "IterationEndNode",
      nodeCategory: "Element",
      nodeClass: "element",
      nodeData: {},
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      isTargetHandlesEditable: true,
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "ELEMENT",
          handleName: "element",
          handleType: "source",
          handleCategory: "element",
        },
      ],
      isSourceHandlesEditable: true,
      ignoreSourceHandlesDiff: true,
      isEditable: true,
      isReady: true,
    },
  ];
};

export default useInitialNodeTypes;
