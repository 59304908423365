import { ExoticComponent } from "react";
import { NodeProps } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import ArrayLoopNode from "./ArrayLoopNode";
import AssignQuestNode from "./AssignQuestNode";
import BaseNode from "./base/BaseNode";
import BooleanNode from "./BooleanNode";
import BooleanOperatorNode from "./BooleanOperatorNode";
import CappyEvaluationNode from "./CappyEvaluationNode";
import CreateAiCompletionNode from "./CreateAiCompletionNode";
import CreateExtractValueNode from "./CreateExtractValueNode";
import CreateFetchSessionNode from "./CreateFetchSessionNode";
import CustomConditionNode from "./CustomConditionNode";
import EndNode from "./EndNode";
import ExecuteWorkflowNode from "./ExecuteWorkflowNode";
import FetchDataNode from "./FetchDataNode";
import FlowNode from "./FlowNode";
import IntersectionJoinNode from "./IntersectionJoinNode";
import IterationEndNode from "./IterationEndNode";
import IterationStartNode from "./IterationStartNode";
import NpcKeyValueFindNode from "./NpcKeyValueFindNode";
import NpcKeyValueUpdateNode from "./NpcKeyValueUpdateNode";
import PlayerKeyValueFindNode from "./PlayerKeyValueFindNode";
import PlayerKeyValueUpdateNode from "./PlayerKeyValueUpdateNode";
import LongTermMemoryNode from "./LongTermMemoryNode";
import NPCActionNode from "./NPCActionNode";
import SemanticSearchNode from "./SemanticSearchNode";
import SetSessionStatusNode from "./SetSessionStatusNode";
import StartNode from "./StartNode";
import TransformDataNode from "./TransformDataNode";

export const nodeTypes: Record<string, ExoticComponent<NodeProps<NodeType>>> = {
  ArrayLoopNode,
  AssignQuestNode,
  BaseNode,
  BooleanNode,
  BooleanOperatorNode,
  CappyEvaluationNode,
  CreateAiCompletionNode,
  CreateExtractValueNode,
  CreateFetchSessionNode,
  CustomConditionNode,
  EndNode,
  ExecuteWorkflowNode,
  FetchDataNode,
  FlowNode,
  IntersectionJoinNode,
  IterationEndNode,
  IterationStartNode,
  LongTermMemoryNode,
  NpcKeyValueFindNode,
  NpcKeyValueUpdateNode,
  NPCActionNode,
  PlayerKeyValueFindNode,
  PlayerKeyValueUpdateNode,
  SemanticSearchNode,
  SetSessionStatusNode,
  StartNode,
  TransformDataNode,
};

export const nodeTypeDictionary: Record<string, ExoticComponent<NodeProps<NodeType>>> = Object.fromEntries(
  Object.entries(nodeTypes).map(([nodeTypeName, nodeType]) => [nodeTypeName, nodeType])
);
