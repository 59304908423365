import { Box, FormControl, FormLabel, Input, Stack } from "@chakra-ui/react";
import React, { memo, useCallback } from "react";
import { useForm } from "react-hook-form";
import { NodeProps } from "reactflow";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { NodeType } from "../../../models/nodeType";
import NpcSelect from "../../base/NpcSelect";
import JSONSchemaEditor from "../JSONSchemaEditor";
import { BaseNodeWithChildren } from "./base/BaseNode";

interface NpcKeyValueFindData {
  npcId: string;
  key: string;
  outputSchema: string;
}

const NpcKeyValueFindNode: React.FC<NodeProps<NodeType<NpcKeyValueFindData>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const npcId = nodeData?.npcId ?? "";
  const key = nodeData?.key ?? "";
  const outputSchema = nodeData?.outputSchema ?? "";

  const { control, register, handleSubmit, setValue } = useForm<NpcKeyValueFindData>({
    defaultValues: {
      npcId,
      key,
      outputSchema,
    },
  });

  const { updateNodeData } = useUpdateNodeData(nodeId);

  const handleUpdate = useCallback((sessionStatus: NpcKeyValueFindData) => {
    updateNodeData(sessionStatus);
  }, []);

  return (
    <BaseNodeWithChildren {...props}>
      <FormControl
        className={"nodrag"}
        onSubmit={handleSubmit(handleUpdate)}
        onBlur={handleSubmit(handleUpdate)}
        onChange={handleSubmit(handleUpdate)}
      >
        <NpcSelect control={control} color={color} />
        <Stack>
          <Box py={1} w="25rem">
            <FormLabel textTransform={"uppercase"} color={color}>
              Key
            </FormLabel>
            <Input type={"text"} placeholder="key" {...register("key")} />
          </Box>
          <Box py={1}>
            <FormLabel textTransform={"uppercase"} color={color}>
              Output Schema
            </FormLabel>
            <JSONSchemaEditor name="outputSchema" control={control} height="25vh" />
          </Box>
        </Stack>
      </FormControl>
    </BaseNodeWithChildren>
  );
};

export default memo(NpcKeyValueFindNode);
