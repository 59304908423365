/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormLabel } from "@chakra-ui/form-control";
import { Text } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
import { Select } from "chakra-react-select";
import { Control, Controller } from "react-hook-form";

interface NpcSelectData {
  userId: string;
}

interface NpcSelectProps<T extends Record<string, any>> {
  control: Control<NpcSelectData & T, unknown>;
  onChange?: (npcId?: string) => void;
  color?: string;
}

function PlayerSelect<T extends Record<string, any>>(props: NpcSelectProps<T>) {
  const { control, onChange: onSelectChange, color } = props;

  const playerIdOptions = [{ label: "{{userId}}", value: "{{userId}}" }];

  return (
    <Box w="full">
      <FormLabel>
        <Text casing={"uppercase"} color={color}>
          Player
        </Text>
      </FormLabel>
      <Controller
        name={"userId" as any}
        control={control}
        render={({ field: { onChange, onBlur, value, name, ref } }) => (
          <Select
            onBlur={onBlur}
            name={name}
            ref={ref}
            isLoading={false}
            isDisabled={false}
            value={playerIdOptions && value ? playerIdOptions.find((option) => option.value === value) : null}
            onChange={(option) => {
              if (onSelectChange) {
                onSelectChange(option?.value);
              }
              return option && onChange(option.value);
            }}
            options={playerIdOptions}
          />
        )}
      />
    </Box>
  );
}

export default PlayerSelect;
